<template>
  <div>

    <main>
      <section class="relative w-full h-full py-40 min-h-screen">
        <div class="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
          :style="`background-image: url('${registerBg2}'); filter: blur(5px);`"></div>
        <router-view />

      </section>
    </main>
  </div>
</template>
<script>
//import Navbar from "@/components/Navbars/AuthNavbar.vue";
//import FooterSmall from "@/components/Footers/FooterSmall.vue";

import registerBg2 from "@/assets/img/home-banner.png";

export default {
  data() {
    return {
      registerBg2,
    };
  },
  components: {
    //Navbar,
    //FooterSmall,
  },
};
</script>
