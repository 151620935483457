<template>
  <div>
    <div v-if="show"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex top-0 left-0 w-full h-screen"
      style="background-color: rgba(0,0,0,.5);">
      <div class="relative my-6 mx-auto" style="width: calc(100% - 20px); max-width: 800px;">
        <!--content-->
        <div
          class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-2xl font-semibold">Transaction History</h3>
            <button
              class="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              @click="close">
              <span class="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto" style="max-height: 50vh; overflow-y: auto;">
            <div v-if="loading" class="flex justify-center items-center p-4">
              <div class="spinner"></div>
            </div>
            <div v-else class="p-4">
              <div class="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                <div class="flex-auto p-4">
                  <div class="flex flex-wrap">
                    <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
                      <h5 class="text-blueGray-400 uppercase font-bold text-xs text-left">
                        Friends List
                      </h5>
                      <ul v-if="friends && friends.length" class="list-none">
                        <li v-for="friend in friends" :key="friend.id" class="flex items-center mb-4 p-2 border-b border-blueGray-200">
                          <div class="w-16 h-16 mr-4 flex-shrink-0">
                            <img :src="friend.connected_user.avatar" class="w-full h-full rounded-full border-2 border-blueGray-50 shadow cursor-pointer" />
                          </div>
                          <div class="text-left">
                            <div class="font-semibold text-blueGray-700">
                              {{ friend.connected_user.first_name }} {{ friend.connected_user.last_name }}
                            </div>
                            <div class="text-sm text-blueGray-500">
                              Email: {{ friend.connected_user.email }}
                            </div>
                            <div class="text-sm text-blueGray-500">
                              Phone: {{ friend.connected_user.country_code }} {{ friend.connected_user.phone }}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
            <button
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button" @click="close">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="show" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>


<script>
import axios from '../../../axios';

export default {
  props: {
    show: Boolean,
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      friends: [],
      loading: true,
    };
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.fetchFriendList();
      }
    }
  },
  methods: {
    async fetchFriendList() {
      try {
        this.loading = true;
        const token = localStorage.getItem('token');
        const response = await axios.post('/users/getChildConnections', { user_id: this.userId }, {
          headers: {
            'X-XSRF-TOKEN': token,
          },
        });
        this.friends = response.data.data;
      } catch (error) {
        console.error('Error fetching transaction history:', error);
      } finally {
        this.loading = false;
      }
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>
