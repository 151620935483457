<template>
  <div>
    <div v-if="show"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex top-0 left-0 w-full h-screen"
      style="background-color: rgba(0,0,0,.5);">
      <div class="relative my-6 mx-auto" style="width: calc(100% - 20px); max-width: 800px;">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-2xl font-semibold">Transaction History</h3>
            <button
              class="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              @click="close">
              <span class="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto" style="max-height: 50vh; overflow-y: auto;">
            <!-- Filter Form -->
            <div class="p-4">
              <div class="flex items-center mb-2">
                <label class="mr-4">
                  <input type="radio" v-model="filterType" value="all" @change="applyFilters"> All
                </label>
                <label class="mr-4">
                  <input type="radio" v-model="filterType" value="deposit" @change="applyFilters"> Money in
                </label>
                <label>
                  <input type="radio" v-model="filterType" value="spending" @change="applyFilters"> Money out
                </label>
              </div>
              <div class="flex items-center mb-2">
                <label class="mr-2">From:</label>
                <input type="date" v-model="fromDate" @change="applyFilters" />
                <label class="ml-4 mr-2">To:</label>
                <input type="date" v-model="toDate" @change="applyFilters" />
              </div>
            </div>
            <div v-if="loading" class="flex justify-center items-center p-4">
              <div class="spinner"></div>
            </div>
            <div v-else class="p-4">
              <ul v-if="transactions && transactions.length">
                <li class="text-left" v-for="transactionGroup in transactions" :key="transactionGroup.date">
                  <strong class="mx-4">{{ transactionGroup.date }}</strong>
                  <ul class="list-ul">
                    <li v-for="transaction in transactionGroup.list" :key="transaction.transaction_id">
                      {{ transaction.user_name }}: PKR {{ transaction.amount }} - {{ transaction.type }}
                      <div v-if="transaction.type !== 'deposit'" class="transaction-details">
                        <div>Date: {{ transaction.date }}</div>
                        <div>Transaction ID: {{ transaction.transaction_id ?? 'N/A' }}</div>
                        <div>Status: Success</div>
                        <div v-if="transaction.receipt && transaction.receipt.sender_name && transaction.receipt.sender_number">
                          From: {{ transaction.receipt.sender_name }} phone: {{ transaction.receipt.sender_number }}
                        </div>
                        <div v-if="transaction.receipt && transaction.receipt.receiver_name && transaction.receipt.receiver_number">
                          Recipient: {{ transaction.receipt.receiver_name }} phone: {{ transaction.receipt.receiver_number }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
              <div v-else>No transactions available.</div>
            </div>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
            <button
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button" @click="close">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="show" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>


<script>
import axios from '../../../axios';

export default {
  props: {
    show: Boolean,
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      transactions: [],
      loading: true,
      filterType: 'all', // Default filter type
      fromDate: '',
      toDate: ''
    };
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.fetchTransactions();
      }
    }
  },
  methods: {
    async fetchTransactions() {
      try {
        this.loading = true;
        //const userId = this.$route.params.id;
        const token = localStorage.getItem('token');
        const response = await axios.get('/users/transactionHistory', {
          headers: {
            'X-XSRF-TOKEN': token,
          },
          params: {
            user_id: this.userId,
            type: this.filterType,
            from_date: this.fromDate,
            to_date: this.toDate,
          },
        });
        this.transactions = response.data.data.transactions;
      } catch (error) {
        console.error('Error fetching transaction history:', error);
      } finally {
        this.loading = false;
      }
    },
    applyFilters() {
      this.fetchTransactions();
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>
