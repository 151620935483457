<template>
  <div>
    <div
      class="fixed inset-0 z-60"
      style="background-color: rgba(0, 0, 0, 0.5); inset: 0"
    ></div>
    <div
      class="absolute bg-white shadow p-4 rounded"
      style="top: 50%; left: 50%; transform: translate(-50%, -50%)"
    >
      <h2 class="text-2xl text-center bold">Confirmation</h2>
      <p class="text-md block" style="margin: 10px 0px">
        Do you want to UNLINK this account?
      </p>
      <div class="flex justify-center">
        <button
          class="p-2 rounded bg-red-500 text-white"
          :disabled="loader"
          @click="handleUnLinkedAccount"
        >
          <span v-if="loader">Loading...</span>
          <span v-else>Yes, Unlink Account</span>
        </button>
        <button
          class="p-2 rounded text-red-500 bg-white"
          @click="$emit('close')"
        >
          No, Not Now
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../axios";
import { defineComponent, ref } from "vue";
export default defineComponent({
  props: ["childId", "parentId"],
  setup(props, context) {
    const loader = ref(false);
    const handleUnLinkedAccount = async () => {
      loader.value = true;
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
          "users/removeUserAccessForChild",
          { linked_parent_id: props.parentId, child_id: props.childId },
          {
            headers: {
              "X-XSRF-TOKEN": token,
            },
          }
        );
        console.log(response);

        // const data = response.data.data;
      } catch (error) {
        console.log("error", error);
      } finally {
        loader.value = false;
        context.emit('close');
      }
    };

    return {
      loader,
      handleUnLinkedAccount,
    };
  },
});
</script>