<template>
    <div class="flex flex-wrap mt-4">
      <div class="w-full mb-12 px-4">
        <request-table />
      </div>
    </div>
  </template>
  
  <script>
  import RequestTable from "@/components/Telescope/RequestsTable";
  
  export default {
    components: {
      RequestTable,
    },
  };
  </script>
  