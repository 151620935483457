<template>
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']">
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-lg" :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']">
            Users
          </h3>
        </div>
        <div class="relative w-full px-4 max-w-full flex-grow mt-4">
          <input v-model="search" @input="fetchUsers" type="text" placeholder="Search by keyword"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
          
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Tenants table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
              ID
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
              Name
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
              Email
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
              Phone
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
              Status
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
              Profile Status
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
              Role
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loading">
            <td colspan="7" class="text-center py-4">
              <div class="spinner-wrapper"><div class="spinner"></div></div>
            </td>
          </tr>
          <tr v-else v-for="user in users" :key="user.id">
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              <span class="font-bold text-blueGray-600">{{ user.id }}</span>
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              <span class="font-bold text-blueGray-600">{{ user.first_name }} {{ user.last_name }}</span>
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              <span class="font-bold text-blueGray-600">{{ user.email }}</span>
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              <span class="font-bold text-blueGray-600">{{ user.country_code }} {{ user.phone }}</span>
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              <i
                :class="user.status === 'active' ? 'fas fa-circle text-orange-500 mr-2' : 'fas fa-circle text-emerald-500 mr-2'"></i>
              {{ user.status === 'active' ? 'Active' : 'Inactive' }}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {{ user.profile_status}}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {{ user.role }}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
              <user-table-dropdown :userId="user.id" :currentStatus="user.status"
                @status-updated="handleStatusUpdated" @edit-user="handleEditUser" @account-overview="handleAccountOverview"/>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import axios from '../../axios';
import UserTableDropdown from "@/components/Users/UserTableDropdown.vue";


export default {
  data() {
    return {
      users: [],  // To hold the tenant data
      search: '',   // To hold the search query
      color: 'light',  // Default color
      loading: false,  // Loading state
    };
  },
  methods: {
    async fetchUsers() {
      this.loading = true;  // Set loading state to true
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/users/get-all-users', {
          headers: {
            'X-XSRF-TOKEN': token,
          },
          params: {
            search: this.search,
          },
        });

        console.log('All users data');
        console.log(response.data.data);

        this.users = response.data.data;

       

      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        this.loading = false;  // Set loading state to false
      }
    },

    handleStatusUpdated(userId, newStatus) {
      const user = this.users.find(t => t.id === userId);
      if (user) {
        user.status = newStatus;
      }
    },

    handleEditUser(userId) {
      this.$router.push(`/admin/users/edit/${userId}`); // Redirect to the edit page
    },

    handleAccountOverview(userId) {
      this.$router.push(`/admin/users/overview/${userId}`); // Redirect to the edit page
    },
  },
  created() {
    // Fetch tenants initially when the component is created
    this.fetchUsers();
  },
  components: {
    UserTableDropdown,
  },
};
</script>
