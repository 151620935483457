<template>
  <div>
    <div v-if="show"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex top-0 left-0 w-full h-screen"
      style="background-color: rgba(0,0,0,.5);">
      <div class="relative my-6 mx-auto" style="width: calc(100% - 20px); max-width: 800px;">
        <!--content-->
        <div
          class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-2xl font-semibold">Parent Profile</h3>
            <button
              class="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              @click="close">
              <span class="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <!-- Profile Details -->
            <div class="p-4">
              <div class="flex flex-wrap justify-center">
                <div class="w-full px-4 flex justify-center">
                  <div class="relative">
                    <img alt="..." :src="avatar"
                      class="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px" />
                  </div>
                </div>
                <div class="w-full px-4 text-center mt-20">
                  <div class="flex justify-center py-4 lg:pt-4 pt-8">
                    <div class="mr-4 p-3 text-center">
                      <span class="text-sm font-bold block tracking-wide text-blueGray-600">
                        Gender
                      </span>
                      <span class="text-sm text-blueGray-400">{{ gender }}</span>
                    </div>
                    <div class="mr-4 p-3 text-center">
                      <span class="text-sm font-bold block tracking-wide text-blueGray-600">
                        phone
                      </span>
                      <span class="text-sm text-blueGray-400">{{ country_code }} {{ phone }}</span>
                    </div>
                    <div class="lg:mr-4 p-3 text-center">
                      <span class="text-sm font-bold block tracking-wide text-blueGray-600">
                        Date of Birth
                      </span>
                      <span class="text-sm text-blueGray-400">{{ dob }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center mt-12">
                <div v-if="loading" class="flex justify-center items-center p-4">
                  <div class="spinner"></div>
                </div>
                <h3 class="text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                  {{ first_name }} {{ last_name }}
                </h3>
                <div class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold">
                  <span class="text-sm font-bold block tracking-wide text-blueGray-600">
                    {{ email }}
                  </span>
                  <span class="text-sm font-bold block tracking-wide text-blueGray-600">
                    {{ cnic }}
                  </span>
                </div>
                <div class="mb-2 text-blueGray-600 mt-10">
                  <h5 class="font-bold">Interests</h5>
                  <p v-if="interests">{{ interests }}</p>
                </div>

                <div class="mb-2 text-blueGray-600 mt-10">
                  <h5 class="font-bold">Linked Accounts</h5>
                  <div class="flex justify-center">
                    <img v-for="(acc, index) in linkedAcc" :key="acc.id" :src="acc.avatar" class="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4 cursor-pointer" @click="handleLinkedAccount" :class="{'-ml-4': index}">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
            <button
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button" @click="close">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="show" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>



<script>
import axios from '../../../axios';
import team2 from "@/assets/img/team-1-800x800.jpg";

export default {
  props: {
    show: Boolean,
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      avatar: team2,
      loading: true,
      phone: '',
      country_code: '',
      dob: '',
      gender: '',
      interests: {},
      address: '',
      country_id: '',
      city_id: '',
      cnic: '',
      cnic_issue_date: '',
      cnic_expiry_date: '',
      country: '',
      city: '',
      first_name: '',
      last_name: '',
      email: '',
      linkedAcc: []
    };
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.fetchUserProfile();
        this.getLinkedAccount();
      }
    }
  },
  methods: {
    
    async fetchUserProfile() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/users/getUserProfile', {
          headers: {
            'X-XSRF-TOKEN': token,
          },
          params: {
            user_id: this.userId
          }
        });

        const data = response.data.data;

        console.log(data);

        this.avatar = 'https://dev.angle.com.pk/uploads/profile_pic/' + this.getSafeValue(data.profile_pic),
        this.phone = this.getSafeValue(data.phone),
        this.country_code = this.getSafeValue(data.country_code),
        this.dob = this.getSafeValue(data.dob),
        this.gender = this.getSafeValue(data.gender),
        this.interests = this.convertInterestsToCSV(data.interests),
        this.address = this.getSafeValue(data.address),
        this.country_id = this.getSafeValue(data.country_id),
        this.city_id = this.getSafeValue(data.city_id),
        this.cnic = this.getSafeValue(data.cnic),
        this.cnic_issue_date = this.getSafeValue(data.cnic_issue_date),
        this.cnic_expiry_date = this.getSafeValue(data.cnic_expiry_date),
        this.country = this.getSafeValue(data.country),
        this.city = this.getSafeValue(data.city),
        this.first_name = this.getSafeValue(data.first_name),
        this.last_name = this.getSafeValue(data.last_name),
        this.email = this.getSafeValue(data.email)


      } catch (error) {
        console.error('Error fetching user profile:', error);
      } finally {
        this.loading = false;
      }
    },

    async getLinkedAccount() {
      try {
        const token = localStorage.getItem('token');
        const AccId = this.$route.params.id;
        const response = await axios.get('/users/getChildAssignedToLinkedParent', {
          headers: {
            'X-XSRF-TOKEN': token,
          },
          params: {
            linked_parent_id: this.userId,
            user_id: AccId
          }
        });

        const data = response.data.data;

        console.log('linkedAcc', data.linked_childs);
        this.linkedAcc = data.linked_childs;

      } catch (error) {
        console.error('Error fetching user profile:', error);
      } finally {
        this.loading = false;
      }
    },

    async handleLinkedAccount() {
      console.log('clicked')
    },

    convertInterestsToCSV(interests) {
      if (interests != '' && Array.isArray(interests) && interests.length > 0) {
        return interests.map(interest => interest.name).join(', '); // Assuming interests is an array of objects with a 'name' property
      }
      return '';
    },
    getSafeValue(value) {
      return value === null || value === '' ? 'N/A' : value;
    },
    close() {
      this.$emit('close');
    }
  },


};
</script>