<template>
  <div>
    <div v-if="show"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex top-0 left-0 w-full h-screen"
      style="background-color: rgba(0,0,0,.5);">
      <div class="relative my-6 mx-auto" style="width: calc(100% - 20px); max-width: 800px;">
        <!--content-->
        <div
          class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-2xl font-semibold">Spending Limits</h3>
            <button
              class="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              @click="close">
              <span class="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto" style="max-height: 50vh; overflow-y: auto;">
            <div v-if="loading" class="flex justify-center items-center p-4">
              <div class="spinner"></div>
            </div>
            <div v-else class="p-4">
              <div class="mb-4">
                <label for="per_day_limit" class="block text-sm font-bold text-left">Per Day Limit</label>
                <input id="per_day_limit" type="number" v-model="per_day_limit" :disabled="no_limit"
                  class="border rounded p-2 w-full" />
              </div>
              <div class="mb-4">
                <label for="per_transaction_limit" class="block text-sm font-bold text-left">Per Transaction Limit</label>
                <input id="per_transaction_limit" type="number" v-model="per_transaction_limit" :disabled="no_limit"
                  class="border rounded p-2 w-full" />
              </div>
              <div class="mb-4 flex items-center">
                <input type="checkbox" id="no_limit" :checked="no_limit === 1" @change="handleNoLimitChange"
                  class="mr-2" />
                <label for="no_limit" class="text-sm font-bold">No Limit</label>
              </div>
            </div>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
            <div v-if="!loading" class="text-center mr-2">
                <button class="bg-red-500 text-white px-4 py-2 rounded" @click="submitLimits">
                  Submit
                </button>
              </div>
            <button
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-sm"
              type="button" @click="close">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="show" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>

<script>
import axios from '../../../axios';

export default {
  props: {
    show: Boolean,
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      per_day_limit: 0,
      per_transaction_limit: 0,
      no_limit: 0,
    };
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.fetchProfile();
      }
    }
  },
  methods: {
    async fetchProfile() {
      try {
        this.loading = true;
        const token = localStorage.getItem('token');
        const response = await axios.get('/users/getUserProfile', {
          headers: {
            'X-XSRF-TOKEN': token,
          },
          params: {
            user_id: this.userId
          }
        });

        const data = response.data.data;
        this.per_day_limit = this.convertToNumber(data.per_day_limit);
        this.per_transaction_limit = this.convertToNumber(data.per_transaction_limit);
        this.no_limit = data.no_limit;
      } catch (error) {
        console.error('Error fetching user profile:', error);
      } finally {
        this.loading = false;
      }
    },

    async submitLimits() {
      try {
        console.log(this.userId);
        const token = localStorage.getItem('token');
        const AccId = this.$route.params.id;

        await axios.post('/users/setSpendingLimit', {
          per_day_limit: this.per_day_limit,
          per_transaction_limit: this.per_transaction_limit,
          no_limit: this.no_limit,
          child_id: this.userId,
          user_id: AccId
        }, {
          headers: {
            'X-XSRF-TOKEN': token,
          }
        });

        alert('Spending limits updated successfully!');
      } catch (error) {
        console.error('Error updating spending limits:', error);
      }
    },

    handleNoLimitChange(event) {
      this.no_limit = event.target.checked ? 1 : 0;
      if (this.no_limit === 1) {
        this.per_day_limit = 0;
        this.per_transaction_limit = 0;
      }
    },

    convertToNumber(value) {
      if (typeof value === 'string') {
        return parseFloat(value.replace(/,/g, '')) || 0;
      }
      return value;
    },

    getSafeValue(value) {
      return value === null || value === '' ? 0 : value;
    },

    close() {
      this.$emit('close');
    }
  }
};
</script>
