<template>
  <div class="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
    <div class="flex-auto p-4">
      <div class="flex flex-wrap">
        <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
          <h5 class="text-blueGray-400 uppercase font-bold text-xs">
            {{ statSubtitle }}
          </h5>
          <span class="font-semibold text-xl text-blueGray-700">
            <span v-if="loading" class="spinner"></span>
            <span v-else>{{ balance }}</span>
          </span>
        </div>
        <div class="relative w-auto pl-4 flex-initial">
          <div
            class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full"
            :class="[statIconColor]">
            <i :class="[statIconName]"></i>
          </div>
        </div>
      </div>
      <p class="text-sm text-blueGray-400 mt-4">
        <span class="whitespace-nowrap"><a href="#" @click.prevent="openModal">Transaction History</a></span>
      </p>
    </div>

    <!-- TransactionHistoryModal -->
    <TransactionHistoryModal :show="isModalOpen" @close="isModalOpen = false" />
  </div>
</template>

<script>
import TransactionHistoryModal from '@/components/Users/Overview/TransactionHistoryModal.vue';
import axios from '../../../axios'; // Ensure axios is imported

export default {
  name: "card-stats",
  props: {
    statSubtitle: {
      type: String,
      default: "Balance",
    },
    statTitle: {
      type: String,
      default: "350,897",
    },
    statArrow: {
      default: "up",
      validator: function (value) {
        // The value must match one of these strings
        return ["up", "down"].indexOf(value) !== -1;
      },
    },
    statPercent: {
      type: String,
      default: "3.48",
    },
    // can be any of the text color utilities
    // from tailwindcss
    statPercentColor: {
      type: String,
      default: "text-emerald-500",
    },
    statDescripiron: {
      type: String,
      default: "Since last month",
    },
    statIconName: {
      type: String,
      default: "far fa-chart-bar",
    },
    // can be any of the background color utilities
    // from tailwindcss
    statIconColor: {
      type: String,
      default: "bg-red-500",
    },
  },
  data() {
    return {
      balance: 'Loading...', // Placeholder balance
      loading: false, // Loading state
      isModalOpen: false,
    };
  },
  created() {
    this.fetchBalance();
  },
  methods: {
    async fetchBalance() {
      try {
        const userId = this.$route.params.id; // Get user ID from the URL
        const token = localStorage.getItem('token');
        const response = await axios.get('/users/getBalance', {
          headers: {
            'X-XSRF-TOKEN': token,
          },
          params: {
            get_limit: 1,
            id: userId,
          },
        });
        this.balance = 'PKR ' + response.data.data.current_balance; // Assuming the response contains the balance
      } catch (error) {
        console.error('Error fetching balance:', error);
        this.balance = 'Error'; // Handle error state
      } finally {
        this.loading = false; // Set loading to false after API call
      }
    },
    
    openModal() {
      this.isModalOpen = true;
    },
  },
  components: {
    TransactionHistoryModal,
  },
};
</script>