<template>
  <div>
    <a class="text-blueGray-500 py-1 px-3" href="#pablo" ref="btnDropdownRef" v-on:click="toggleDropdown($event)">
      <i class="fas fa-ellipsis-v"></i>
    </a>
    <div ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48" v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }">
      <a v-on:click.prevent="openConnectionUserProfilewModal" href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700">
        User Profile
      </a>
      <a @click.prevent="openConnectionUserSpendingLimitwModal" href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700">
        Spending Limits
      </a>
      <a @click.prevent="openConnectionUserTransactionHistorywModal" href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700">
        Transaction History
      </a>
      <!-- <a href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700">
        Money Requests
      </a> -->
      <a @click.prevent="openConnectionUserFriendsListwModal" href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700">
        View Friends List
      </a>
    </div>

    <!-- TransactionHistoryModal -->
    <ConnectionUserOverviewModal :show="isUserOverviewModalOpen" @close="isUserOverviewModalOpen = false" />
    <ConnectionUserProfileModal :userId="userId" :show="isUserProfileModalOpen" @close="isUserProfileModalOpen = false" />
    <ConnectionUserSpendingLimitModal :userId="userId" :show="isUserSpendingLimitModalOpen" @close="isUserSpendingLimitModalOpen = false" />
    <ConnectionUserTransactionHistoryModal :userId="userId" :show="isUserTransactionHistoryModalOpen" @close="isUserTransactionHistoryModalOpen = false" />
    <ConnectionUserFriendsListModal :userId="userId" :show="isUserFriendsListModalOpen" @close="isUserFriendsListModalOpen = false" />
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";
import ConnectionUserOverviewModal from '@/components/Users/Overview/ConnectionUserOverviewModal.vue';
import ConnectionUserProfileModal from '@/components/Users/Overview/ConnectionUserProfileModal.vue';
import ConnectionUserSpendingLimitModal from '@/components/Users/Overview/ConnectionUserSpendingLimitModal.vue';
import ConnectionUserTransactionHistoryModal from '@/components/Users/Overview/ConnectionUserTransactionHistoryModal.vue';
import ConnectionUserFriendsListModal from '@/components/Users/Overview/ConnectionUserFriendsListModal.vue';

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      dropdownPopoverShow: false,
      isUserOverviewModalOpen: false,
      isUserProfileModalOpen: false,
      isUserSpendingLimitModalOpen: false,
      isUserTransactionHistoryModalOpen: false,
      isUserFriendsListModalOpen: false,
    };
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
    openConnectionUserOverviewModal() {
      this.isUserOverviewModalOpen = true;
    },
    openConnectionUserProfilewModal() {
      this.isUserProfileModalOpen = true;
    },
    openConnectionUserSpendingLimitwModal() {
      this.isUserSpendingLimitModalOpen = true;
    },
    openConnectionUserTransactionHistorywModal() {
      this.isUserTransactionHistoryModalOpen = true;
    },
    openConnectionUserFriendsListwModal() {
      this.isUserFriendsListModalOpen = true;
    },
  },
  components: {
    ConnectionUserOverviewModal,
    ConnectionUserProfileModal,
    ConnectionUserSpendingLimitModal,
    ConnectionUserTransactionHistoryModal,
    ConnectionUserFriendsListModal,
  },
};
</script>
