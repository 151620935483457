<template>
  <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16">
    <div class="px-6">
      <div class="flex flex-wrap justify-center">
        <div class="w-full px-4 flex justify-center">
          <div class="relative">
            <img alt="..." :src="avatar"
              class="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px" />
          </div>
        </div>
        <div class="w-full px-4 text-center mt-20">
          <div class="flex justify-center py-4 lg:pt-4 pt-8">
            <div class="mr-4 p-3 text-center">
              <span class="text-sm font-bold block tracking-wide text-blueGray-600">
                Gender
              </span>
              <span class="text-sm text-blueGray-400">{{ gender }}</span>
            </div>
            <div class="mr-4 p-3 text-center">
              <span class="text-sm font-bold block tracking-wide text-blueGray-600">
                phone
              </span>
              <span class="text-sm text-blueGray-400">{{ country_code }}{{ phone }}</span>
            </div>
            <div class="lg:mr-4 p-3 text-center">
              <span class="text-sm font-bold block tracking-wide text-blueGray-600">
                Date of Birth
              </span>
              <span class="text-sm text-blueGray-400">{{ dob }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-12">
        <div v-if="loading" class="flex justify-center items-center p-4">
            <div class="spinner"></div>
          </div>
        <h3 class="text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
          {{ first_name }} {{ last_name }}
        </h3>
        <div class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold">
          <i class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>
          {{ city.name }}, {{ country.name }}
          <span class="text-sm font-bold block tracking-wide text-blueGray-600">
            {{ email }}
          </span>
          <span class="text-sm font-bold block tracking-wide text-blueGray-600">
            {{ cnic }}
          </span>
        </div>
        <div class="mb-2 text-blueGray-600 mt-10">
          <h5 class="font-bold">Interests</h5>
          {{ interests }}
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import team2 from "@/assets/img/team-1-800x800.jpg";
import axios from '../../../axios'; // Adjust this path as needed

export default {
  data() {
    return {
      avatar: team2,
      loading: true,
      phone: '',
      country_code: '',
      dob: '',
      gender: '',
      interests: {},
      address: '',
      country_id: '',
      city_id: '',
      cnic: '',
      cnic_issue_date: '',
      cnic_expiry_date: '',
      country: '',
      city: '',
      first_name: '',
      last_name: '',
      email: '',

    };
  },
  created() {
    this.fetchProfile();
  },
  methods: {
    async fetchProfile() {
      try {

        const userId = this.$route.params.id; // Get user ID from the URL
        const token = localStorage.getItem('token');
        const response = await axios.get('/users/getProfile', {
          headers: {
            'X-XSRF-TOKEN': token,
          },
          params: {
            id: userId,
          },
        });

        const data = response.data.data;

        console.log(data.avatar);

          this.avatar = 'https://dev.angle.com.pk/uploads/profile_pic/' + data.profile_pic,
          this.phone = data.phone,
          this.country_code = data.country_code,
          this.dob = data.dob,
          this.gender = data.gender,
          this.interests = this.convertInterestsToCSV(data.interests),
          this.address = data.address,
          this.country_id = data.country_id,
          this.city_id = data.city_id,
          this.cnic = data.cnic,
          this.cnic_issue_date = data.cnic_issue_date,
          this.cnic_expiry_date = data.cnic_expiry_date,
          this.country = data.country,
          this.city = data.city,
          this.first_name = data.first_name,
          this.last_name = data.last_name,
          this.email = data.email

      } catch (error) {
        console.error('Error fetching profile:', error);
      } finally {
        this.loading = false;
      }
    },
    convertInterestsToCSV(interests) {
      if (Array.isArray(interests)) {
        return interests.map(interest => interest.name).join(', '); // Assuming interests is an array of objects with a 'name' property
      }
      return '';
    },
  },

};
</script>
