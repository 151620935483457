<template>
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-700">
    <div class="rounded-t mb-0 px-4 py-3 bg-transparent">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h6 class="uppercase text-blueGray-100 mb-1 text-xs font-semibold">
            Overview
          </h6>
          <h2 class="text-white text-xl font-semibold">
            API Hits
          </h2>
        </div>
      </div>
    </div>
    <div class="p-4 flex-auto">
      <!-- Chart -->
      <div class="relative h-350-px">
        <div v-if="apiHitsStore.loading" class="spinner-wrapper">
          <div class="spinner"></div>
        </div>
        <canvas id="line-chart"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
//import { defineStore } from 'pinia';
import Chart from "chart.js";
//import axios from '../../axios';
import { useApiHitsStore } from '../../stores/apiHits';

export default {
  setup() {
    const apiHitsStore = useApiHitsStore();
    apiHitsStore.fetchApiData();
    return { apiHitsStore };
  },
  mounted() {
    if (this.apiHitsStore.apiData.labels.length === 0) {
      this.apiHitsStore.fetchApiData().then(() => {
        this.renderChart();
      });
    } else {
      this.renderChart();
    }
  },
  methods: {
    renderChart() {
      const config = {
        type: 'line',
        data: {
          labels: this.apiHitsStore.apiData.labels,
          datasets: [
            {
              label: 'Successful',
              backgroundColor: '#fff',
              borderColor: '#fff',
              data: this.apiHitsStore.apiData.successful,
              fill: false,
            },
            {
              label: 'Failed',
              backgroundColor: '#e3342f',
              borderColor: '#e3342f',
              data: this.apiHitsStore.apiData.failed,
              fill: false,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          title: {
            display: false,
            text: 'API Hits by Month',
            fontColor: 'white',
          },
          legend: {
            labels: {
              fontColor: 'white',
            },
            align: 'end',
            position: 'bottom',
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          hover: {
            mode: 'nearest',
            intersect: true,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: 'rgba(255,255,255,.7)',
                },
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: 'Month',
                  fontColor: 'white',
                },
                gridLines: {
                  display: false,
                  borderDash: [2],
                  borderDashOffset: [2],
                  color: 'rgba(33, 37, 41, 0.3)',
                  zeroLineColor: 'rgba(0, 0, 0, 0)',
                  zeroLineBorderDash: [2],
                  zeroLineBorderDashOffset: [2],
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: 'rgba(255,255,255,.7)',
                },
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: 'Hits',
                  fontColor: 'white',
                },
                gridLines: {
                  borderDash: [3],
                  borderDashOffset: [3],
                  drawBorder: false,
                  color: 'rgba(255, 255, 255, 0.15)',
                  zeroLineColor: 'rgba(33, 37, 41, 0)',
                  zeroLineBorderDash: [2],
                  zeroLineBorderDashOffset: [2],
                },
              },
            ],
          },
        },
      };
      const ctx = document.getElementById('line-chart').getContext('2d');
      window.myLine = new Chart(ctx, config);
    },
  },
};
</script>

<style scoped>
.h-350-px {
  height: 350px;
}
</style>