<template>
  <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
    <div class="rounded-t mb-0 px-4 py-3 bg-transparent">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h6 class="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
            Performance
          </h6>
          <h2 class="text-blueGray-700 text-xl font-semibold">
            Hits By Year
          </h2>
        </div>
      </div>
    </div>
    <div class="p-4 flex-auto">
      <div class="relative h-350-px">
        <div v-if="apiHitsStore.loading" class="spinner-wrapper">
          <div class="spinner"></div>
        </div>
        <canvas id="bar-chart"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import { useApiHitsStore } from '../../stores/apiHitsByYear'; // Adjust the import path as needed
import Chart from 'chart.js';

export default {
  setup() {
    const apiHitsStore = useApiHitsStore();
    apiHitsStore.fetchApiData();

    return { apiHitsStore };
  },
  mounted() {
    if (this.apiHitsStore.apiData.labels.length === 0) {
      this.apiHitsStore.fetchApiData().then(() => {
        this.renderChart();
      });
    } else {
      this.renderChart();
    }
  },
  methods: {
    renderChart() {
      const config = {
        type: 'bar',
        data: {
          labels: this.apiHitsStore.apiData.labels,
          datasets: [
            {
              label: 'Total API Hits',
              backgroundColor: '#ed64a6',
              borderColor: '#ed64a6',
              data: this.apiHitsStore.apiData.totals,
              fill: false,
              barThickness: 8,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          title: {
            display: false,
            text: 'API Hits by Year',
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          hover: {
            mode: 'nearest',
            intersect: true,
          },
          legend: {
            labels: {
              fontColor: 'rgba(0,0,0,.4)',
            },
            align: 'end',
            position: 'bottom',
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: 'Year',
                },
                gridLines: {
                  borderDash: [2],
                  borderDashOffset: [2],
                  color: 'rgba(33, 37, 41, 0.3)',
                  zeroLineColor: 'rgba(33, 37, 41, 0.3)',
                  zeroLineBorderDash: [2],
                  zeroLineBorderDashOffset: [2],
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: 'Hits',
                },
                gridLines: {
                  borderDash: [2],
                  drawBorder: false,
                  borderDashOffset: [2],
                  color: 'rgba(33, 37, 41, 0.2)',
                  zeroLineColor: 'rgba(33, 37, 41, 0.15)',
                  zeroLineBorderDash: [2],
                  zeroLineBorderDashOffset: [2],
                },
              },
            ],
          },
        },
      };
      const ctx = document.getElementById('bar-chart').getContext('2d');
      window.myBar = new Chart(ctx, config);
    },
  },
};
</script>

<style scoped>
.h-350-px {
  height: 350px;
}
</style>