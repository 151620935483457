import { defineStore } from 'pinia';
import axios from '../axios'; // Adjust the import path as needed

export const useApiHitsStore = defineStore('apiHits', {
  state: () => ({
    apiData: {
      labels: [],
      successful: [],
      failed: [],
    },
    loading: false,
  }),
  actions: {
    async fetchApiData(forceReload = false) {
      if (!forceReload && this.apiData.labels.length > 0) {
        return; // Data is already fetched, no need to fetch again
      }

      this.loading = true;
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/telescope/api-hits', {
          headers: {
            'X-XSRF-TOKEN': token,
          },
        });
        this.apiData = response.data.data;
      } catch (error) {
        console.error('Error fetching API data:', error);
      } finally {
        this.loading = false;
      }
    },
  },
});