import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app

import App from "@/App.vue";
import { createPinia } from 'pinia';

// layouts

import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";

// views for Admin layout

import Dashboard from "@/views/admin/Dashboard.vue";
import Settings from "@/views/admin/Settings.vue";
import Tables from "@/views/admin/Tables.vue";
import Maps from "@/views/admin/Maps.vue";
import Users from "@/views/admin/Users/AllUsers.vue";
import EditUser from "@/views/admin/Users/EditUser.vue";
import AccountOverview from "@/views/admin/Users/AccountOverview.vue";

import TelescopeRequests from "@/views/admin/Telescope/Requests.vue";
import TelescopeRequestDetail from "@/views/admin/Telescope/RequestDetail.vue";

// views for Auth layout

import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";
import Logout from "@/views/auth/Logout.vue";

// views without layouts

import Landing from "@/views/Landing.vue";
import Profile from "@/views/Profile.vue";
import Index from "@/views/Index.vue";


// routes

const routes = [
  {
      path: "/admin",
      redirect: "/admin/dashboard",
      component: Admin,
      children: [
          {
            path: "/admin/dashboard",
            component: Dashboard,
            meta: { requiresAuth: true },
          },
          {
            path: "/admin/users",
            component: Users,
            meta: { requiresAuth: true, breadcrumb: 'Users' },
          },
          {
            path: "/admin/users/edit/:id",
            component: EditUser,
            meta: { requiresAuth: true, breadcrumb: 'Edit User' },
          },
          {
            path: "/admin/users/overview/:id",
            component: AccountOverview,
            meta: { requiresAuth: true, breadcrumb: 'Account Overview' },
          },
          {
            path: "/admin/telescope/requests",
            component: TelescopeRequests,
            meta: { requiresAuth: true, breadcrumb: 'Requests' },
          },
          {
            path: "/admin/telescope/requests/:id",
            component: TelescopeRequestDetail,
            meta: { requiresAuth: true, breadcrumb: 'Request Details' },
          },
          {
            path: "/admin/settings",
            component: Settings,
          },
          {
            path: "/admin/tables",
            component: Tables,
          },
          {
            path: "/admin/maps",
            component: Maps,
          },
      ],
  },
  {
      path: "/auth",
      redirect: "/auth/login",
      component: Auth,
      children: [
          {
            path: "/auth/login",
            component: Login,
          },
          {
            path: "/auth/register",
            component: Register,
          },
          {
            path: "/auth/logout",
            component: Logout,
          },
      ],
  },
  {
      path: "/landing",
      component: Landing,
  },
  {
      path: "/profile",
      component: Profile,
  },
  {
      path: "/",
      component: Index,
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = !!localStorage.getItem('token'); // Check if token exists in localStorage

    if (to.meta.requiresAuth && !isAuthenticated) {
      next('/auth/login'); // Redirect to login if not authenticated and trying to access a protected route
    } else {
      next();
    }
});

const pinia = createPinia();
createApp(App).use(router).use(pinia).mount("#app");
