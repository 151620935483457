<template>
  <!-- Edit User Form -->
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Edit User</h6>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <div v-if="loading" class="spinner-wrapper">
        <div class="spinner"></div>
      </div>
      <form v-else @submit.prevent="updateUser">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          User Information
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user-first-name">
                UserId
              </label>
              <input v-model="user.userId" type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="user-userId" placeholder="userId" readonly required />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user-first-name">
                Calls you
              </label>
              <input v-model="user.calls_you" type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="user-userId" placeholder="Calls you" required />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user-first-name">
                App pin
              </label>
              <input v-model="user.app_pin" type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="user-userId" placeholder="userId" readonly required />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user-first-name">
                First Name
              </label>
              <input v-model="user.first_name" type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="user-first-name" placeholder="First Name" required />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user-last-name">
                Last Name
              </label>
              <input v-model="user.last_name" type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="user-last-name" placeholder="Last Name" required />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user-email">
                Email address
              </label>
              <input v-model="user.email" type="email"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="user-email" placeholder="Email" required />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user-phone">
                Country Code
              </label>
              <input v-model="user.country_code" type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="user-phone-country-code" placeholder="Country Code" />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user-phone">
                Phone
              </label>
              <input v-model="user.phone" type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="user-phone" placeholder="Phone" />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user-phone">
                CNIC
              </label>
              <input v-model="user.cnic" type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="user-cnic" placeholder="CNIC" />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user-phone">
                Address
              </label>
              <input v-model="user.address" type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="user-address" placeholder="Address" />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user-phone">
                Date of Birth
              </label>
              <input v-model="user.dob" type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="user-dob" placeholder="Date of Birth" readonly />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user-gender">
                Gender
              </label>
              <select v-model="user.gender"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="user-gender">
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user-country">
                Country
              </label>
              <select v-model="user.country_id"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="user-country" @change="fetchCities" disabled>
                <option value="">Select Country</option>
                <option v-for="country in countries" :key="country.id" :value="country.id">
                  {{ country.name }}
                </option>
              </select>
            </div>
          </div>

          <!-- City Dropdown -->
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user-city">
                City
              </label>
              <select v-model="user.city_id"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="user-city" disabled>
                <option value="">Select City</option>
                <option v-for="city in cities" :key="city.id" :value="city.id">
                  {{ city.name }}
                </option>
              </select>
            </div>
          </div>

          <!-- <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user-avatar">
              Avatar
            </label>
            <div class="flex items-center">
              <img :src="avatarUrl" alt="User Avatar" class="w-16 h-16 rounded-full mr-4">
              <input type="file" @change="updateAvatar" class="hidden" ref="avatarInput">
              <button @click="triggerFileInput"
                class="bg-blue-500 text-white px-3 py-2 rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150">
                Update Avatar
              </button>
            </div>
          </div> 
          school_name,
          school_branch,
          class_name,
          confirmation_code,
          account_verified_at,
          fb_uid,
          google_uid,
          interests,
          spending_status,
          app_setting,
          per_day_limit,
          per_transaction_limit,
        -->


          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user-phone">
                School Name
              </label>
              <input v-model="user.school_name" type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="user-school-name" placeholder="School Name" />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user-phone">
                School Branch
              </label>
              <input v-model="user.school_branch" type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="user-school-branch" placeholder="School Branch" />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user-phone">
                Class Name
              </label>
              <input v-model="user.class_name" type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="user-class-name" placeholder="Class Name" />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user-phone">
                Confirmation Code
              </label>
              <input v-model="user.confirmation_code" type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="user-confirmation-code" placeholder="Confirmation Code" readonly/>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user-phone">
                Account Verified At
              </label>
              <input v-model="user.account_verified_at" type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="user-account-verified-at" placeholder="Account Verified At" readonly/>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user-phone">
                Fb uid
              </label>
              <input v-model="user.fb_uid" type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="user-fb-uid" placeholder="Fb uid" readonly/>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user-phone">
                Google uid
              </label>
              <input v-model="user.google_uid" type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="user-google-uid" placeholder="Google uid" readonly/>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user-phone">
                Per Day Limit
              </label>
              <input v-model="user.per_day_limit" type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="user-perday-limit" placeholder="Per Day Limit" required/>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user-phone">
                Per Transaction Limit
              </label>
              <input v-model="user.per_transaction_limit" type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="user-pertransaction-limit" placeholder="Per Transaction Limit" required/>
            </div>
          </div>
        </div>

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Password
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user-password">
                Password
              </label>
              <input v-model="user.password" type="password"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="user-password" placeholder="Password" />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user-confirm-password">
                Confirm Password
              </label>
              <input v-model="user.confirm_password" type="password"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="user-confirm-password" placeholder="Confirm Password" />
            </div>
          </div>
        </div>

        <div class="text-center mt-6">
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="submit">
            Update User
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from '../../../axios';

export default {
  data() {
    return {
      user: {
        id: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password: '',
        confirm_password: '',
        userId: '',
        calls_you: '',
        app_pin: '',
        country_code: '',
        cnic: '',
        address: '',
        dob: '',
        gender: '',
        country_id: '',
        city_id: '',
        avatar: '',
      },
      loading: false,
    };
  },
  created() {
    this.fetchUser();
    this.fetchCountries();
  },

  computed: {
    avatarUrl() {
      return this.user.avatar ? this.user.avatar : 'path/to/unknown.png';
    }
  },

  methods: {
    async fetchUser() {
      this.loading = true;
      const user_id = this.$route.params.id;
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`/users/getUserById`, {
          headers: {
            'X-XSRF-TOKEN': token,
          },
          params: {
            id: user_id,
          },
        });
        const {
          id,
          first_name,
          last_name,
          email,
          phone,
          userId,
          calls_you,
          app_pin,
          country_code,
          cnic,
          address,
          dob,
          gender,
          country_id,
          city_id,
          avatar,
          school_name,
          school_branch,
          class_name,
          confirmation_code,
          account_verified_at,
          fb_uid,
          google_uid,
          interests,
          spending_status,
          app_setting,
          per_day_limit,
          per_transaction_limit,

        } = response.data.data; // Destructure response data

        this.user = {
          id: id,
          userId,
          first_name,
          last_name,
          email,
          phone,
          calls_you,
          app_pin,
          country_code,
          cnic,
          address,
          dob,
          gender,
          country_id,
          city_id,
          avatar,
          school_name,
          school_branch,
          class_name,
          confirmation_code,
          account_verified_at,
          fb_uid,
          google_uid,
          interests,
          spending_status,
          app_setting,
          per_day_limit,
          per_transaction_limit,
          word: '',
          confirm_password: ''
        }; // Clear passwords

        await this.fetchCities();
      } catch (error) {
        console.error('Error fetching user:', error);
      } finally {
        this.loading = false;
      }
    },

    async fetchCountries() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/getCountries', {
          headers: {
            'X-XSRF-TOKEN': token,
          },
        });
        this.countries = response.data.data;
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    },

    async fetchCities() {
      if (this.user.country_id) {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(`/getCitiesByCountry/${this.user.country_id}`, {
            headers: {
              'X-XSRF-TOKEN': token,
            },
          });
          this.cities = response.data.data;
        } catch (error) {
          console.error('Error fetching cities:', error);
        }
      } else {
        this.cities = [];
      }
    },

    async updateUser() {
      this.loading = true;
      const userId = this.$route.params.id;
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`/users/updateById`, {
          ...this.user,
          user_id: userId // Include the ID in the payload
        }, {
          headers: {
            'X-XSRF-TOKEN': token,
          },
        });
        console.log('User updated successfully:', response.data);
        this.$router.push('/admin/users'); // Redirect to users list page
      } catch (error) {
        console.error('Error updating user:', error);
      } finally {
        this.loading = false;
      }
    },

    triggerFileInput() {
      this.$refs.avatarInput.click();
    },
  },
};
</script>
