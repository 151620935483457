<template>
  <!-- Navbar -->
  <nav
    class="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
    <div class="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
      <!-- Brand -->
      <!-- <a class="text-white text-sm uppercase hidden lg:inline-block font-semibold" href="javascript:void(0)">
        Dashboard
      </a> -->

      <!-- Breadcrumbs -->
      <div class="text-white text-sm hidden md:flex md:items-center md:ml-4">
        <router-link class="text-white text-sm uppercase hidden lg:inline-block font-semibold pr-minor" to="/admin/dashboard">Dashboard</router-link>
        <template v-if="breadcrumbs.length">
          <span v-for="(breadcrumb, index) in breadcrumbs" :key="index">
            <span class="mx-2 font-semibold">/</span>
            <router-link v-if="breadcrumb.link" class="text-white text-sm uppercase hidden lg:inline-block font-semibold" :to="breadcrumb.link">{{ breadcrumb.text }}</router-link>
            <span class="text-white text-sm uppercase hidden lg:inline-block font-semibold pl-minor" v-else>{{ breadcrumb.text }}</span>
          </span>
        </template>
      </div>

      <!-- Form -->
      
      <!-- User -->
      <ul class="flex-col md:flex-row list-none items-center hidden md:flex">
        <user-dropdown />
      </ul>
    </div>
  </nav>
  <!-- End Navbar -->
</template>

<script>
import UserDropdown from "@/components/Dropdowns/UserDropdown.vue";

export default {
  components: {
    UserDropdown,
  },
  data() {
    return {
      breadcrumbs: [],
    };
  },
  watch: {
    $route() {
      this.updateBreadcrumbs();
    },
  },
  created() {
    this.updateBreadcrumbs();
  },
  methods: {
    updateBreadcrumbs() {
      const matched = this.$route.matched
      .filter(route => route.meta && route.meta.breadcrumb); // Filter routes with defined breadcrumb

      this.breadcrumbs = matched.map((route, index) => ({
        text: route.meta.breadcrumb,
        link: route.path !== this.$route.path ? route.path : null,
        showSeparator: index < matched.length - 1 // Boolean to determine if a separator should be shown
      }));
    },
  },
};
</script>
