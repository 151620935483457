<template>
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']">
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-lg" :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']">
            Requests
          </h3>
        </div>
        <div class="relative w-full px-4 flex-grow flex-1">
          <!-- Add any additional buttons or links here -->
        </div>
        <div class="relative w-full px-4 max-w-full flex-grow mt-4">
          <input v-model="search" @input="fetchRequests(true)" type="text" placeholder="Search by keyword"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Requests table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
              Verb
            </th>
            <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
              Path
            </th>
            <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
              Status
            </th>
            <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
              Duration
            </th>
            <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
              Happened
            </th>
            <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loading && requests.length === 0">
            <td colspan="6" class="text-center py-4">
              <div class="spinner-wrapper"><div class="spinner"></div></div>
            </td>
          </tr>
          <tr v-for="request in requests" :key="request.uuid">
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              <span class="font-bold text-blueGray-600">{{ request.content.method }}</span>
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {{ request.content.uri }}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {{ request.content.response_status }}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {{ request.content.duration }} ms
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {{ formatDate(request.created_at) }}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              <router-link :to="`/admin/telescope/requests/${request.uuid}`">
                <i class="fas fa-arrow-right text-blueGray-600"></i>
              </router-link>
            </td>
          </tr>
          <tr v-if="loading && requests.length > 0">
            <td colspan="6" class="text-center py-4">
              <div class="spinner-wrapper"><div class="spinner"></div></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex justify-center my-4">
      <button v-if="hasMore" @click="loadMore" class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150">
        Load More
      </button>
    </div>
  </div>
</template>

<script>
import axios from '../../axios';  // Adjust import as needed
import moment from 'moment';  // Import moment

export default {
  data() {
    return {
      requests: [],  // To hold the request data
      search: '',   // To hold the search query
      page: 1,  // Current page
      lastPage: 1,  // Last page number
      loading: false,  // Loading state
      color: 'light',  // Default color
    };
  },
  computed: {
    hasMore() {
      return this.page < this.lastPage;
    }
  },
  methods: {
    async fetchRequests(reset = false) {
      if (reset) {
        this.page = 1;
        this.requests = [];
      }

      this.loading = true;  // Set loading state to true
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/telescope/requests', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          params: {
            search: this.search,
            page: this.page
          },
        });

        const { entries, current_page, last_page } = response.data.data;

        if (reset) {
          this.requests = entries;
        } else {
          // Merge new entries, ensuring no duplicates based on uuid
          const existingUUIDs = this.requests.map(request => request.uuid);
          const newEntries = entries.filter(entry => !existingUUIDs.includes(entry.uuid));
          this.requests = [...this.requests, ...newEntries];
        }

        this.page = current_page;
        this.lastPage = last_page;
      } catch (error) {
        console.error('Error fetching requests:', error);
      } finally {
        this.loading = false;  // Set loading state to false
      }
    },

    async fetchLatestRequests() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/telescope/requests', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          params: {
            search: this.search,
            page: 1
          },
        });

        const { entries } = response.data.data;

        // Merge new entries at the top, ensuring no duplicates based on uuid
        const existingUUIDs = this.requests.map(request => request.uuid);
        const newEntries = entries.filter(entry => !existingUUIDs.includes(entry.uuid));
        this.requests = [...newEntries, ...this.requests];
      } catch (error) {
        console.error('Error fetching latest requests:', error);
      }
    },

    async loadMore() {
      if (this.page < this.lastPage) {
        this.page++;
        await this.fetchRequests();
      }
    },

    formatDate(date) {
      return moment(date).fromNow();  // Use moment to format the date
    }
  },
  created() {
    // Fetch requests initially when the component is created
    this.fetchRequests();

    // Fetch latest requests every 4 seconds
    setInterval(() => {
      this.fetchLatestRequests();
    }, 4000);
  }
};
</script>
