import { defineStore } from 'pinia';
import axios from '../axios'; // Adjust the import path as needed

export const useApiHitsStore = defineStore('apiHitsByYear', {
  state: () => ({
    apiData: {
      labels: [],
      totals: [],
    },
    loading: false,
  }),
  actions: {
    async fetchApiData() {
      if (this.apiData.labels.length > 0) {
        return; // Data is already fetched, no need to fetch again
      }
      this.loading = true;
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/telescope/api-hits-by-year', {
          headers: {
            'X-XSRF-TOKEN': token,
          },
        });
        const data = response.data.data;
        this.apiData.labels = data.map(item => item.year);
        this.apiData.totals = data.map(item => item.total);
      } catch (error) {
        console.error('Error fetching API data:', error);
      } finally {
        this.loading = false;
      }
    },
  },
});
