<template>
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']">
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-lg" :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']">
            Connections
          </h3>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Loading Spinner -->
      <div v-if="loading" class="flex justify-center items-center py-10">
        <div class="spinner"></div>
      </div>
      <!-- Projects table -->
      <table v-if="!loading" class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
              User
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
              Role
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
              Calls you
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
              Connection ID
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
              Is Primary
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="connection in connections" :key="connection.id">
            <th
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
              <img :src="getAvatarUrl(connection.connected_user.avatar)" class="h-12 w-12 bg-white rounded-full border" alt="..." />
              <span class="ml-3 font-bold" :class="[
                color === 'light' ? 'text-blueGray-600' : 'text-white',
              ]">
                {{ connection.connected_user.first_name }} {{ connection.connected_user.last_name }}
              </span>
            </th>
            <td class="border-t-0 px-6 uppercase align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {{ removeUnderscores(connection.role) }}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {{ connection.connected_user.calls_you }}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {{ connection.connection_id }}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {{ isPrimary(connection.is_primary) }}
            </td>
            <td v-if="connection.role == 'child'" class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
              <child-dropdown :user-id="connection.connected_user.id" />
            </td>
            <td v-if="connection.role == 'linked_parent'" class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
              <parent-dropdown :user-id="connection.connected_user.id" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ChildDropdown from "@/components/Users/Overview/ChildDropdown.vue";
import ParentDropdown from "@/components/Users/Overview/ParentDropdown.vue";
import team1 from "@/assets/img/team-1-800x800.jpg";
import axios from '../../../axios'; // Adjust this path as needed

export default {
  data() {
    return {
      connections: [],
      fake_avatar: team1,
      loading: true,
      userId: '',
    };
  },
  components: {
    ChildDropdown,
    ParentDropdown,
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  created() {
    this.fetchProfile();
  },
  methods: {
    async fetchProfile() {
      try {
        const userId = this.$route.params.id; // Get user ID from the URL
        const token = localStorage.getItem('token');
        const response = await axios.get('/users/getUserConnections', {
          headers: {
            'X-XSRF-TOKEN': token,
          },
          params: {
            id: userId,
          },
        });

        this.connections = response.data.data.user_connections;

        console.log(this.connections.user_connections);

      } catch (error) {
        console.error('Error fetching profile:', error);
      } finally {
        this.loading = false;
      }
    },
    getAvatarUrl(avatar) {
      if (!avatar) {
        return this.fake_avatar;
      }
      const newDomain = 'https://dev.angle.com.pk/';
      const avatarPath = avatar.split('/').slice(3).join('/');
      return `${newDomain}/${avatarPath}`;
    },
    removeUnderscores(string) {
      return string.replace(/_/g, ' ');
    },
    isPrimary(number) {
      return number == 1 ? 'Yes' : 'No';
    },
  },
  
};
</script>
