<template>
  <div>
    <div class="flex flex-wrap">
      <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
        <card-balance />

        <div class="w-full xl:w-12/12 mb-12 xl:mb-0 mt-8">
          <card-table />
        </div>
        
      </div>
      <div class="w-full xl:w-4/12 px-4">
        <card-profile />
      </div>
    </div>
    <div class="flex flex-wrap mt-4">
      
      <!-- <div class="w-full xl:w-4/12 px-4">
        <card-social-traffic />
      </div> -->
    </div>
  </div>
</template>
<script>
import CardBalance from "@/components/Users/Overview/CardBalance.vue";
import CardProfile from "@/components/Users/Overview/CardProfile.vue";
import CardTable from "@/components/Users/Overview/CardTable.vue";
//import CardSocialTraffic from "@/components/Cards/CardSocialTraffic.vue";
export default {
  name: "dashboard-page",
  components: {
    CardBalance,
    CardProfile,
    CardTable,
    // CardSocialTraffic,
  },
};
</script>
