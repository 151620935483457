<template>
    <div>
      <p>Logging out...</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Logout',
    mounted() {
      // Perform logout actions here, e.g., clear localStorage
      localStorage.removeItem('token');
      // Redirect to login page after logout
      this.$router.push('/auth/login');
    }
  };
  </script>
  
  <style>
  /* Add your styles here */
  </style>
  