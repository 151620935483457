<template>
  <div>
    <!-- Dropdown Toggle Button -->
    <a class="text-blueGray-500 py-1 px-3" href="#pablo" ref="btnDropdownRef" v-on:click="toggleDropdown($event)">
      <i class="fas fa-ellipsis-v"></i>
    </a>

    <!-- Dropdown Menu -->
    <div ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48" v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }">
      <a v-on:click.prevent="openConnectionUserProfilewModal" href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700">
        User Profile
      </a>
      <a @click.prevent="openConnectionUserTransactionHistorywModal" href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700">
        Transaction History
      </a>
    </div>

    <!-- TransactionHistoryModal -->
    <ConnectionParentUserProfileModal :userId="userId" :show="isUserProfileModalOpen" @close="isUserProfileModalOpen = false" />
    <ConnectionUserTransactionHistoryModal :userId="userId" :show="isUserTransactionHistoryModalOpen" @close="isUserTransactionHistoryModalOpen = false" />
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import ConnectionParentUserProfileModal from '@/components/Users/Overview/ConnectionParentUserProfileModal.vue';
import ConnectionUserTransactionHistoryModal from '@/components/Users/Overview/ConnectionUserTransactionHistoryModal.vue';

export default {
  props: {
    userId: {
      type: String,
      required: true
    },
  },

  data() {
    return {
      dropdownPopoverShow: false,
      isUserProfileModalOpen: false,
      isUserTransactionHistoryModalOpen: false,
    };
  },

  methods: {
    toggleDropdown(event) {
      event.preventDefault();
      this.dropdownPopoverShow = !this.dropdownPopoverShow;
      if (this.dropdownPopoverShow) {
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },

    openConnectionUserTransactionHistorywModal() {
      this.isUserTransactionHistoryModalOpen = true;
    },
    openConnectionUserProfilewModal() {
      this.isUserProfileModalOpen = true;
    },
  },

  components: {
    ConnectionParentUserProfileModal,
    ConnectionUserTransactionHistoryModal,
  },
};
</script>
